import { useEffect, useMemo, useRef, useState } from "react";
import Filter from "components/filter";
import { endOfMonth, format, startOfMonth } from "date-fns";
import ViewDatos from "./view-datos";
import { AsignarUsuario } from "./asignar-usuarios";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import { AcuseCep } from "./acuse-cep";
import * as signalR from "@microsoft/signalr";
import { FaHandsHelping } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import BtnNew from "components/btn-new";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import { useDispatch } from "react-redux";
import { setNotificacion } from "../../../redux/slice/auth";
import sound1 from "../../../assets/sound/not_1.mp3";
import sound2 from "../../../assets/sound/not_2.mp3";
import ListSolicitudes from "./list-solicitudes";

const Solicitudes = ({ status }) => {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});
  const [modalAsignar, setModalAsignar] = useState(false);
  const [idGeneralSeleccionado, setidGeneralSeleccionado] = useState("");
  const [modalAcuse, setModalAcuse] = useState(false);
  const [loader, setIsloader] = useState(false);
  const [connection, setConnection] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [textStatus, setTextStatus] = useState("");

  const { REACT_APP_URL_BASE } = process.env;
  const audio1 = new Audio(sound1);
  const audio2 = new Audio(sound2);
  const timeoutIdRef = useRef(null); // Usamos useRef para mantener el ID del timeout

  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(
    format(startOfMonth(new Date()), "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(
    format(endOfMonth(new Date()), "yyyy-MM-dd")
  );
  const [loading, setLoading] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isConnected, setIsConneced] = useState();
  const handleFilter = () => {
    getAll();
  };

  const token = useMemo(() => {
    return localStorage.getItem("auth");
  });

  const rol = useMemo(() => {
    return parseInt(localStorage.getItem("rol"));
  });

  const getValueByPath = (obj, path) => {
    return path.split(".").reduce((o, p) => (o ? o[p] : undefined), obj);
  };

  const dataGrid = useMemo(() => {
    if (!data) return [];

    const lowerTextSearch = textSearch?.toLowerCase() || "";
    const lowerTextStatus = textStatus?.toLowerCase() || "";

    return data.filter((item) => {
      // Filtrar por el estado
      if (lowerTextStatus) {
        const statusValue = getValueByPath(item, "general.estatus");
        if (
          !statusValue ||
          statusValue.toString().toLowerCase() !== lowerTextStatus
        ) {
          return false; // No coincide el estado, excluir este item
        }
      }

      // Filtrar por el texto de búsqueda
      return ["general.id", "general.estatus", "general.nombre"].some((key) => {
        const value = getValueByPath(item, key);
        if (value !== undefined && value !== null) {
          const valueStr = value.toString().toLowerCase();
          return valueStr.includes(lowerTextSearch);
        }
        return false;
      });
    });
  }, [data, textSearch, textStatus]);

  const closeModal = () => {
    setModalOpen(false);
    getAll();
    setFormData({});
  };

  const closeModalAsignar = () => {
    setModalAsignar(false);
    setidGeneralSeleccionado(null);
    getAll();
  };

  useEffect(() => {
    getAll();
    getCount()
  }, [status]);

  useEffect(() => {
    let newConnection = null;

    if (token) {
      const $url = "https://api.app.midinerito.mx";
      newConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${$url}/real-time`, {
          accessTokenFactory: () => token,
          skipNegotiation: false,
        })
        .withAutomaticReconnect()
        .build();

      setConnection(newConnection);
    }
    return () => {
      newConnection && newConnection.stop();
    };
  }, [token]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          console.log("Connected!");
          setIsConneced(true);
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [connection]);

  useEffect(() => {
    if (isConnected && connection) {
      connection.on("ReceiveMessage", (user, message) => {
        console.log(typeof message);
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
        }

        if (typeof message === "string") {
          const { type } = JSON.parse(message);
          switch (type) {
            case "solicitada":
              toast.success("Ha llegado una nueva solicitud", {
                duration: 3000,
                position: "bottom-right",
              });
              playSound(audio1);
              break;
            case "cancelada":
            case "reasignacion":
            case "por-atender":
            case "Devuelta":
            case "proceso":
              playSound(audio2);
              break;
            default:
              break;
          }

          if (type !== "update") {
            getCount();
          }
        }
        getAll();
      });
    }
  }, [isConnected, connection, rol]);

  const playSound = (audio) => {
    audio.play();
    timeoutIdRef.current = setTimeout(() => {
      audio.pause();
      audio.currentTime = 0;
    }, 2000);
  };

  const getAll = () => {
    const query = `start=${startDate}&end=${endDate}&status=${status ?? ""}`;
    const endpoint = `${REACT_APP_URL_BASE}/solicitudes?${query}`;
    const $options = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    setLoading(true);
    fetch(endpoint, $options)
      .then((res) => res.json())
      .then((json) => {
        if (Array.isArray(json)) {
          setData(json); // Asigna la respuesta al estado data
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getCount = () => {
    const endpoint = `${REACT_APP_URL_BASE}/solicitudes/count-sidebar`;
    const options = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    fetch(endpoint, options)
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          dispatch(setNotificacion(json));
        }
      });
  };

  const openModal = (item) => {
    setModalOpen(true);
    setFormData(item);
  };

  const handleView = async (item) => {
    openModal(item);
    if (item.general.estatus === "solicitada") {
      const options = {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify("proceso"),
      };
      const endpoint = `${REACT_APP_URL_BASE}/solicitudes/${item.general.id}/change-status`;
      await fetch(endpoint, options).finally(() => {
        getAll();
      });
    }
  };

  const handleSendConfirmEmail = (id) => {
    Swal.fire({
      title: "Se reenviara un correo de confirmación.",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cerrar",
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const options = {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        };
        const endpoint = `${REACT_APP_URL_BASE}/solicitudes/${id}/send-confirm-email`;
        fetch(endpoint, options).finally(() => {
          toast.success("El correo se ha enviado", {
            duration: 3000,
            position: "top-right",
          });
        });
      }
    });
  };

  const handleConfirmar = (id) => {
    Swal.fire({
      title: "Autorizar.",
      text: "Se autorizara la solicitud",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cerrar",
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsloader(true);

        const options = {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        };
        const endpoint = `${REACT_APP_URL_BASE}/solicitudes/${id}/authorization`;
        fetch(endpoint, options)
          .then((res) => res.json())
          .then((json) => {
            if (json.message) {
              toast.success(json.message, {
                duration: 3000,
                position: "top-right",
              });
            } else {
              toast.success("La solicitud ha sido autorizada", {
                duration: 3000,
                position: "top-right",
              });
            }
            return json;
          })
          .finally(() => {
            setIsloader(false);
            getAll();
          });
      }
    });
  };

  const handleDownload = ({ id }) => {
    const endpoint = REACT_APP_URL_BASE + `/solicitudes/download-zip/${id}`;
    const options = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    setIsloader(true);
    fetch(endpoint, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al descargar el archivo ZIP");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `paquete-credito-${id}.zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {})
      .finally(() => {
        setIsloader(false);
      });
  };

  const autorizar = ({ status, id, isShow, acuse }) => {
    if (!isShow) return null;
    if (status !== "proceso") return null;
    return (
      <BtnNew
        handleClick={() => {
          if ([4, 6].includes(rol) && !acuse) {
            return Swal.fire({
              title: "Permiso",
              text: "Se autorizara la solicitud si acuse",
              showCancelButton: true,
              confirmButtonText: "Aceptar",
              cancelButtonText: "Cerrar",
              icon: "warning",
            }).then(async (result) => {
              if (result.isConfirmed) {
                handleConfirmar(id);
              }
            });
          }

          if (!acuse) {
            return Swal.fire({
              title: "Aviso",
              text: "No se ha generado el acuse cep, intente más tarde",
            });
          } else {
            return handleConfirmar(id);
          }
        }}
        visible={[1, 4, 6].includes(rol)}
        Icon={FaHandsHelping}
        colorIcon={"text-green-300"}
      >
        Autorizar solicitud
      </BtnNew>
    );
  };

  const CancelarSolicitud = ({ id, status }) => {
    if (status == "proceso") {
      return (
        <BtnNew
          handleClick={() => {
            Swal.fire({
              title: "Cancelar.",
              text: "Se cancelara la solicitud",
              showCancelButton: true,
              confirmButtonText: "Aceptar",
              cancelButtonText: "Cerrar",
              icon: "warning",
              input: "text",
              inputPlaceholder: "Ingrese el comentario de cancelar",
              inputValidator: (value) => {
                if (!value) {
                  return "Este campo es requerido";
                }
              },
            }).then(async (result) => {
              if (result.isConfirmed) {
                setIsloader(true);
                const options = {
                  method: "PUT",
                  headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify("cancelada"),
                };
                const userInput = result.value;
                const endpoint = `${REACT_APP_URL_BASE}/solicitudes/${id}/change-status?comment=${userInput}`;
                await fetch(endpoint, options).finally(() => {
                  getAll();
                  setIsloader(false);
                });
              }
            });
          }}
          visible={[1, 4, 6].includes(rol)}
          Icon={MdCancel}
          colorIcon={"text-red-300"}
        >
          Cancelar solicitud
        </BtnNew>
      );
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = dataGrid.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(dataGrid.length / itemsPerPage);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 ">
        <Filter
          values={{
            startDate,
            endDate,
          }}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleFilter={handleFilter}
          showFilterText={true}
          setTextSearch={setTextSearch}
          textSearch={textSearch}
          textStatus={textStatus}
          setTextStatus={setTextStatus}
          showStatus={true}
        />

        {loading ? (
          <div className="flex h-screen items-center justify-center">
            <div className="loader h-32 w-32 rounded-full border-8 border-t-8 border-gray-200 ease-linear"></div>
          </div>
        ) : (
          <>
            <div className="mt-5 flex flex-col gap-5">
              {currentItems.map((item) => {
                return (
                  <ListSolicitudes
                    item={item}
                    rol={rol}
                    loader={loader}
                    status={status}
                    handleView={handleView}
                    handleSendConfirmEmail={handleSendConfirmEmail}
                    handleDownload={handleDownload}
                    autorizar={autorizar}
                    CancelarSolicitud={CancelarSolicitud}
                    setModalAcuse={setModalAcuse}
                    setidGeneralSeleccionado={setidGeneralSeleccionado}
                    setModalAsignar={setModalAsignar}
                  />
                );
              })}
            </div>
            <div className="mt-4 flex items-center justify-start">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="mr-2 rounded-l bg-gray-300 px-4 py-2 font-bold text-gray-800 hover:bg-gray-400"
              >
                <GrFormPrevious />
              </button>
              {Array.from(Array(totalPages).keys()).map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber + 1)}
                  className={`mr-2 rounded-full bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 ${
                    pageNumber + 1 === currentPage ? "bg-blue-700" : ""
                  }`}
                >
                  {pageNumber + 1}
                </button>
              ))}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={indexOfLastItem >= dataGrid.length}
                className="ml-2 rounded-r bg-gray-300 px-4 py-2 font-bold text-gray-800 hover:bg-gray-400"
              >
                <MdNavigateNext />
              </button>
              <span className="ml-2">
                Registros{" "}
                <span className="font-bold">{data && data.length}</span>{" "}
              </span>
            </div>
          </>
        )}
      </div>

      {modalOpen && (
        <ViewDatos id={formData.general.id} close={closeModal} rol={rol} />
      )}
      {modalAsignar && (
        <AsignarUsuario id={idGeneralSeleccionado} close={closeModalAsignar} />
      )}

      {modalAcuse && (
        <AcuseCep
          clave={idGeneralSeleccionado}
          close={() => {
            setModalAcuse(false);
          }}
          token={token}
        />
      )}
    </div>
  );
};

export default Solicitudes;
